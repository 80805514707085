import * as React from 'react'
import PropTypes from 'prop-types'
import formatText from '@/utils/formatText'

import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  Wrapper,
  Subtitle,
  Content,
  Inner,
  Marks,
  Author
} from './style'

const Quote = ({ content, img = null, subtitle = null, author = '' }) => (
  <Container className={ `${(img && img.length) && 'hasImage'}` } img={ resolveAssetURL(img) }>
    <Wrapper>
      <Content>
        { subtitle && <Subtitle>{ subtitle }</Subtitle> }
        <Inner dangerouslySetInnerHTML={ { __html: formatText(content) } } />
        { author && (<Author>{author}</Author>) }
      </Content>
    </Wrapper>
  </Container>
)

Quote.propTypes = {
  content: PropTypes.any.isRequired,
  img: PropTypes.string,
  subtitle: PropTypes.string,
  marks: PropTypes.bool,
  author: PropTypes.string
}

export default Quote
