import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentTitle,
  LabelIcon,
  LabelLink,
  LabelText,
  Wrapper
} from './style'
import { Container, Overlay } from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'

import longArrow from '@/images/long-arrow.svg'

const NewsSlider = ({
  title,
  slides,
  scrollDownButton = true
}) => {
  const marketCode = useRecoilValue(marketCodeManager)
  const path = `/${marketCode}/journal/`

  return (
    <Wrapper>
      <LabelLink href={ path }>
        <LabelText>{title}</LabelText>
        <LabelIcon src={ longArrow } />
      </LabelLink>
      <Swiper
        modules={ [Pagination, Autoplay, EffectFade] }
        pagination={ { clickable: true } }
        loop
        shortSwipes={ false }
        longSwipesRatio={ 0.2 }
        autoplay={ { disableOnInteraction: false, delay: 7000 } }
        effect='fade'
        watchSlidesProgress
      >
        {
          slides.map((slide, i) => {
            const {
              background,
              categories,
              title,
              ctaTo
            } = slide
            const date = moment(slide.date).format('LL')
            const category = categories[0]
            return (
              <SwiperSlide key={ `slide-${i}` }>
                <Container background={ resolveAssetURL(background) }>
                  <Overlay>
                    <Content>
                      <ContentCategories>
                        <ContentCategory>
                          {category}
                        </ContentCategory>
                      </ContentCategories>
                      <ContentTitle>{title}</ContentTitle>
                      <ContentDate>{date}</ContentDate>
                      <ContentButton to={ ctaTo }>
                        Learn More
                      </ContentButton>
                    </Content>
                  </Overlay>
                </Container>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </Wrapper>
  )
}

NewsSlider.propTypes = {
  title: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
  scrollDownButton: PropTypes.bool
}

export default NewsSlider
