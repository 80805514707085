import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Item from '@/components/HomeHero/Item'

import { loadingState } from '@/recoil/loader'
import { useRecoilValue } from 'recoil'

import {
  GlobalContainer,
  GlobalCountDownWrapper,
  GlobalWrapper,
  ItemCountDownWrapper,
  ContainerCountDown,
  CountDownOverlay,
  CountDownContent,
  CountDownTitle,
  CountDownContainer,
  CountDownWrapper,
  CountDown,
  Points,
  Time,
  TimeLetter,
  CountDownText,
  Container,
  Overlay,
  Content,
  ContentText,
  Subtitle,
  Title,
  Text,
  Button
} from './style'

const ItemCountDown = ({
  subtitle = '',
  title = '',
  text = '',
  ctaLabel = '',
  ctaTo = '',
  background,
  revealDate = '',
  standardSlideBackground,
  standardSlideTitle,
  standardSlideSubtitle,
  standardSlideDescription,
  standardSlideCtaLabel,
  standardSlideCtaTo
}) => {
  const [days, setDays] = useState('00')
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [endDate, setEndDate] = useState(moment(revealDate, 'YYYY-MM-DD hh:mm:ss'))
  const [displayCountdown, setDisplayCountdown] = useState(true)
  const isLoading = useRecoilValue(loadingState)

  useEffect(() => {
    let countdownInterval

    if (displayCountdown) {
      countdownInterval = setInterval(() => {
        const hasCountdown = moment.utc(new Date()).isBefore(endDate)

        if (!hasCountdown) {
          setDisplayCountdown(hasCountdown)
          clearInterval(countdownInterval)
        } else {
          const duration = moment.duration(endDate.diff(moment()))
          const days = duration.get('days')
          const hours = duration.get('hours')
          const minutes = duration.get('minutes')

          setDays(days < 10 ? '0' + `${days}` : `${days}`)
          setHours(hours < 10 ? `0${hours}` : `${hours}`)
          setMinutes(minutes < 10 ? `0${minutes}` : `${minutes}`)
        }
      }, 1000)
    }

    return () => {
      clearInterval(countdownInterval)
    }
  }, [revealDate])

  return (
    <GlobalContainer className={ `${isLoading ? 'hidden' : 'visible'} ${moment.utc(new Date()).isBefore(moment(revealDate, 'YYYY-MM-DD hh:mm:ss'))}` }>
      <GlobalCountDownWrapper className={ `${displayCountdown && 'active'}` }>
        <ContainerCountDown background={ background }>
          <CountDownOverlay>
            <CountDownContent>
              <CountDownContainer>
                <CountDownTitle>Coming Soon</CountDownTitle>
                <CountDownWrapper>
                  <CountDown>
                    <Time>{days}</Time>
                    <TimeLetter>DAYS</TimeLetter>
                  </CountDown>
                  <CountDown>
                    <Time>{hours}</Time>
                    <TimeLetter>HOURS</TimeLetter>
                  </CountDown>
                  <CountDown>
                    <Points>:</Points>
                  </CountDown>
                  <CountDown>
                    <Time>{minutes}</Time>
                    <TimeLetter>MINUTES</TimeLetter>
                  </CountDown>
                </CountDownWrapper>
              </CountDownContainer>
              <CountDownText>{ text }</CountDownText>
            </CountDownContent>
          </CountDownOverlay>
        </ContainerCountDown>
      </GlobalCountDownWrapper>
      <GlobalWrapper>
        <Item
          subtitle={ standardSlideSubtitle }
          title={ standardSlideTitle }
          text={ standardSlideDescription }
          ctaLabel={ standardSlideCtaLabel }
          ctaTo={ standardSlideCtaTo }
          background={ standardSlideBackground }
        />
      </GlobalWrapper>
    </GlobalContainer>
  )
}

ItemCountDown.propTypes = {
  revealDate: PropTypes.string,
  text: PropTypes.string,
  background: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaTo: PropTypes.string,
  standardSlideBackground: PropTypes.string.isRequired,
  standardSlideTitle: PropTypes.string,
  standardSlideSubtitle: PropTypes.string,
  standardSlideDescription: PropTypes.string,
  standardSlideCtaLabel: PropTypes.string,
  standardSlideCtaTo: PropTypes.string
}

export default ItemCountDown
