import styled from '@emotion/styled'

import CircularLabelButton from '@/components/CircularLabelButton'

import { mediaMax } from '@/styles/mixins'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper {
    width: 100%;

  .swiper-pagination {
      position: absolute;
      left: 0;
      bottom: 5.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 1.5rem;
      width: 100%;
      padding-inline: 11rem;

      ${mediaMax.md} {
        padding-inline: 5rem;
      }

      ${mediaMax.xs} {
        bottom: 3.5rem;
        gap: 3rem;
        padding-inline: 5rem;
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 100%;
        height: 10px;
        flex: 1;
        margin: 0;
        border-radius: 0;
        transition: all ease-out 300ms;
        background: transparent;
        overflow: hidden;

        ${mediaMax.xs} {
          width: unset;
          margin-right: unset;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          opacity: 1;
          border-radius: 5rem;
          background-color: ${({ theme }) => theme.colors.white};
          transition: all ease-out 300ms;
          z-index: 20;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          opacity: 0.3;
          background-color: ${({ theme }) => theme.colors.white};
          transition: all ease-out 300ms;
        }

        &:hover {
          &:after, &:before {
            height: 5px;
          }
        }
      }

      .swiper-pagination-bullet-active {
        &::before {
          animation: slide-progress 7.3s linear forwards;

          .swiper-paused & {
            animation-play-state: paused;
          }
        }
      }

      @keyframes slide-progress {
        0% {
          transform: translateX(-100%);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }
`

export const CircularButton = styled(CircularLabelButton)`
  position: absolute;
  left: calc(50% + 60rem);
  bottom: ${({ theme }) => theme.spacing.medium};
  transform: translateX(-100%);
  z-index: 2;
`
